const XLSX = require("xlsx"); //使用import有的属性无法找到
export function exportExcel(filename, data) {
  let exc = XLSX.utils.book_new(); // 初始化一个excel文件
  let exc_data = XLSX.utils.aoa_to_sheet(data); // 传入数据 , 到excel
  // 将文档插入文件并定义名称
  XLSX.utils.book_append_sheet(exc, exc_data, filename);
  // 执行下载
  XLSX.writeFile(exc, filename + ".xlsx");
}

/**
 * 命令式触发系统文件选择器，并返回所选文件数据
 */
export async function selectFile({ accept = ".*", multiple = false }) {
  return new Promise((resolve) => {
    const id = "liuxing-select-file";
    let el = document.querySelector(`#${id}`);
    if (el) {
      document.body.removeChild(el);
    }
    el = document.createElement("input");
    el.setAttribute("id", id);
    el.setAttribute("type", "file");
    el.setAttribute("accept", accept);
    if (multiple) {
      el.setAttribute("multiple", "multiple");
    }
    el.style.display = "none";
    el.addEventListener("change", (event) => {
      const { files } = event.target;
      if (files) {
        resolve(multiple ? files : files[0]);
      } else {
        resolve(null);
      }
    });
    document.body.append(el);
    el.click();
  });
}
// 命令时选择并读取文件，返回 ArrayBuffer
export function selectFileAsArrayBuffer(options) {
  return new Promise((resolve) => {
    selectFile(options).then((files) => {
      if (!files || files.length === 0) return resolve(null);

      const fileReader = new FileReader();
      fileReader.addEventListener("load", async (e) =>
        resolve(e.target?.result)
      );
      fileReader.readAsArrayBuffer(
        options.multiple === true ? files[0] : files
      );
    });
  });
}

/**
 * 将 Excel 中指定 Sheet 转换为记录列表，配合 {@link selectFileAsArrayBuffer} 一起使用更佳
 */
export function excel2Records({ data, sheetName, header, debug = false }) {
  const workbook = XLSX.read(new Uint8Array(data), { type: "array" });
  debug && console.debug("workbook", workbook);
  // sheetName 缺省时取第一个 sheet
  if (!sheetName) {
    if (workbook.SheetNames.length > 0) {
      sheetName = workbook.SheetNames[0];
    } else {
      throw new Error("Excel 没有 Sheet 可供导入");
    }
  }
  debug && console.debug("sheetName", sheetName);
  const sheet = workbook.Sheets[sheetName];
  debug && console.debug("sheet", sheet);
  const rows = XLSX.utils
    .sheet_to_json(sheet, {
      // 将 excel 的列头按元素索引顺序映射
      header,
    })
    // 过滤掉表头
    .filter((v, index) => index !== 0);
  debug && console.log("rows", rows);
  console.log(rows);
  return rows;
}
